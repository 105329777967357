export const DISHES =
    [

        {
            id: 0,
            name:'Vanilla bean Icecream',
            image: '/assets/images/img11.jpg',
            category: 'mains',
            label:'Hot',
            price:'4.99',
            featured: true,
            description:'Vanilla bean  Icecream filled with special pastry cream and topped with a buttercream all sprinkers,strawberry & Nuts.Orders available for all party.'                    

            },
            {
            id: 1,
            name:'Chocolate Icecream',
            image: '/assets/images/img12.jpg',
            category: 'appetizer',
            label:'',
            price:'1.99',
            featured: false,
            description:'Chocolate Icecream filled with chocolate pastry cream and topped with chocolate buttercream'
            },
            {
            id: 2,
            name:'Brownie Icecream',
            image: '/assets/images/img13.jpg',
            category: 'appetizer',
            label:'New',
            price:'1.99',
            featured: false,
            description:'Brownie Icecream topped with a cookie dough icing'
            },
            {
            id: 3,
            name:'Vanilla streusel Icecream',
            image: '/assets/images/img14.jpg',
            category: 'dessert',
            label:'',
            price:'2.99',
            featured: false,
            description:'Vanilla streusel Icecream topped with maple buttercream and streusel'
            },
            {
                id: 4,
                name:'ElaiCheese Cake',
                image: '/assets/images/img15.jpg',
                category: 'dessert',
                label:'',
                price:'2.99',
                featured: false,
                description:'A delectable, semi-sweet New York Style Cheese Cake, with Graham cracker crust and spiced with Indian cardamoms'
            },
            {
                id: 5,
                name:'Caramel buttercream',
                image: '/assets/images/img16.jpg',
                category: 'dessert',
                label:'',
                price:'2.99',
                featured: false,
                description:'chocolate Icecream filled with salted caramel topped with salted caramel buttercream'
                },
                {
                    id: 6,
                    name:'red wine buttercream ',
                    image: '/assets/images/img17.jpg',
                    category: 'dessert',
                    label:'',
                    price:'2.99',
                    featured: false,
                    description:'vanilla Icecream filled with mixed berry compote topped with red wine buttercream *Made with Alcohol*'
                    },
                    {
                        id: 7,
                        name:'Cookie Icecream',
                        image: '/assets/images/img18.jpg',
                        category: 'dessert',
                        label:'',
                        price:'2.99',
                        featured: false,
                        description:'Cookie Dough Buttercream Stuffed Chocolate Chip Cookies'
                        },
                        {
                            id: 8,
                            name:'Chocolate chip Icecream',
                            image: '/assets/images/img19.jpg',
                            category: 'dessert',
                            label:'',
                            price:'2.99',
                            featured: false,
                            description:'Chocolate chip Icecream with cookie dough icing with chocolate and vanilla drip topped with our signature chocolate chip cookies'
                            },
        {
        id: 9,
        name:'funfetti Icecream',
        image: '/assets/images/img1.jpg',
        category: 'mains',
        label:'Hot',
        price:'4.99',
        featured: true,
        description:'4 layers of funfetti Icecream with our sprinkle buttercream, a white chocolate drip, and topped with our rainbow nonpareil & gold party mix'                    
        },
        {
        id: 10,
        name:'cinnamon Icecream',
        image: '/assets/images/img2.jpg',
        category: 'appetizer',
        label:'',
        price:'1.99',
        featured: false,
        description:'anilla apple cinnamon Icecream topped with apple cider buttercream.'
        },
        {
        id: 11,
        name:'Vanilla & Chocolate',
        image: '/assets/images/img3.jpg',
        category: 'appetizer',
        label:'New',
        price:'1.99',
        featured: false,
        description:'Various Vanilla & Chocolate House Icecreams Daily'
        },
        {
        id: 12,
        name:'Cheese Cake',
        image: '/assets/images/img4.jpg',
        category: 'dessert',
        label:'',
        price:'2.99',
        featured: false,
        description:'A delectable, semi-sweet New York Style Cheese Cake, with Graham cracker crust and spiced with Indian cardamoms'
        },
        {
            id: 13,
            name:'ElaiCheese Cake',
            image: '/assets/images/img5.jpg',
            category: 'dessert',
            label:'',
            price:'2.99',
            featured: false,
            description:'A delectable, semi-sweet New York Style Cheese Cake, with Graham cracker crust and spiced with Indian cardamoms'
        },
        {
            id: 14,
            name:'Starwberry flavor',
            image: '/assets/images/cak1.jpg',
            category: 'dessert',
            label:'',
            price:'2.99',
            featured: false,
            description:'A delectable, semi-sweet New York Style Starwberry flavor Icecream'
            },
            {
                id: 15,
                name:'Coffee flavor',
                image: '/assets/images/img7.jpg',
                category: 'dessert',
                label:'',
                price:'2.99',
                featured: false,
                description:'A special coffee flavor Icecream'
                },
                {
                    id: 16,
                    name:'Mixed berry & Nut',
                    image: '/assets/images/img9.jpg',
                    category: 'dessert',
                    label:'',
                    price:'2.99',
                    featured: false,
                    description:'A delectable, semi-sweet Mixed berry and Nut mixed Icecream'
                    },
                    {
                        id: 17,
                        name:'Kids special',
                        image: '/assets/images/img10.jpg',
                        category: 'dessert',
                        label:'',
                        price:'2.99',
                        featured: false,
                        description:'A delectable with Graham cracker crust and spiced with sprinklers'
                        }
    ];