export const LEADERS = [
    {
      id: 0,
      name: 'Peter Pan',
      image: '/assets/images/rest.jpg',
      designation: 'Chief Epicurious Officer',
      abbr: 'CEO',
      featured: true,
      description: "Our CEO,  credits his hardworking East Asian immigrant parents who undertook the arduous journey to the shores of America with the intention of giving their children the best future."
    },
 

  ];