export const PROMOTIONS = [
    {
      id: 0,
      name: 'Weekend Grand Special',
      image: '/assets/images/img12.jpg',
      label: 'New',
      price: '19.99',
      featured: true,
      description: 'Featuring mouthwatering combinations with a choice of five different icecream,  six main entrees and five choicest desserts. Free flowing bubbly and soft drinks. All for just $19.99 per person'
    }
  ];