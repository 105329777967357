export const ADD_COMMENT = 'ADD_COMMENT';
export const DISHES_LOADING = 'DISHES_LOADING';
export const DISHES_FAILED = 'DISHES_FAILED';
export const ADD_DISHES = 'ADD_DISHES';
export const ADD_COMMENTS = 'ADD_COMMENTS';
export const COMMENTS_FAILED = 'COMMENTS_FAILED';
export const PROMOS_LOADING ='PROMOS_LOADING';
export const ADD_PROMOS = 'ADD_PROMOS';
export const PROMOS_FAILED ='PROMOS_FAILED';
export const LEADERS_LOADING = 'LEADERS_LOADING';
export const LEADERS_FAILED ='LEADERS_FAILED';
export const ADD_LEADERS = 'ADD_LEADERS';