import React from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  CardHeader,
  Media
} from "reactstrap";
import { Link } from "react-router-dom";
import { baseUrl } from "../shared/baseUrl";
import { Loading } from "./LoadingComponent";
import { Fade, Stagger } from "react-animation-components";

function About(props) {
  function RenderLeader({ leader }) {
    return (
      <div className="col-lg-12 col-md-12 col-sm-12">
        <Media center className="" style={{marginLeft:"0px"}}>
          <Media style={{width:"100%",marginLeft:"0px"}} object src={leader.image} alt={leader.name} />
        </Media>
        
      </div>
    );
  }

  function RenderContent({ leaders, isLoading, errMess }) {
    if (isLoading) {
      return <Loading />;
    } else if (errMess) {
      return <h4>{errMess}</h4>;
    } else
      return (
        <Stagger in>
          {props.leaders.leaders.map(leader => (
            <Fade in key={leader.id}>
              <RenderLeader key={leader.id} leader={leader} />
            </Fade>
          ))}
        </Stagger>
      );
  }

  return (
    <div className="container">
     
      <div className="" style={{margin:"0px",width:"100%"}}>
        
    
          <Media style={{margin:"0px",width:"auto",padding:"0px"}}>
            <RenderContent
              leaders={props.leader}
              isLoading={props.leaderLoading}
              errMess={props.leaderErrMess}
            />
          </Media>
        </div>
      <div className="row row-content1">
        <div className="col-12 col-md-12">
          <h2>Our History</h2>
          <p>
            Started in 2010, Dessert Delight quickly established itself as
            a culinary icon par excellence in Hong Kong. With its unique brand
            of world fusion cuisine that can be found nowhere else, it enjoys
            patronage from the A-list clientele in Hong Kong. Featuring four of
            the best three-star Michelin chefs in the world, you never know what
            will arrive on your plate the next time you visit us.
          </p>
          
        </div>
  
      </div>
    
    </div>
  );
}
export default About;