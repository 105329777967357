import React from 'react';
import { Link } from 'react-router-dom';
function Footer(props) {
    return(
    <div className="footer">
        <div className="container">
            <div className="row justify-content-center">             
                <div className="col-4 offset-1 col-sm-2">
                    <h5>Links</h5>
                    <ul className="list-unstyled colorf">
                        <li className="colorf"><Link to='/home'>Home</Link></li>
                        <li className="colorf"><Link to='/aboutus'>About</Link></li>
                        <li className="colorf"><Link to='/menu'>Menu</Link></li>
                        <li className="colorf"><Link to='/contactus'>Contact</Link></li>
                    </ul>
                </div>
                {/*<div className="col-7 col-sm-5 colorf">
                    <h5>Our Address</h5>
                    <address className="colorf">
		              121,  Bay Road<br />
		              Near south station,Thoothukudi<br />
		              India- 628002 <br />
		              <i className="colorf fa fa-phone fa-lg"></i>: +91 1234 5678<br />
		             
		              <i className="colorf fa fa-envelope fa-lg"></i>: <a className="colorf" href="mailto:desserdelight@food.net">
                      desserdelight@food.net</a>
                    </address>
                </div>
                <div className="col-12 col-sm-4 align-self-center colorf">
                    <div className="text-center">
                        <a className="btn btn-social-icon btn-google" href="http://google.com/+"><i className="fa fa-google-plus"></i></a>
                        &nbsp;<a className="btn btn-social-icon btn-facebook" href="http://www.facebook.com/profile.php?id="><i className="fa fa-facebook"></i></a>
                        &nbsp;<a className="btn btn-social-icon btn-linkedin" href="http://www.linkedin.com/in/"><i className="fa fa-linkedin"></i></a>
                        &nbsp;<a className="btn btn-social-icon btn-twitter" href="http://twitter.com/"><i className="fa fa-twitter"></i></a>
                        &nbsp;<a className="btn btn-social-icon btn-google" href="http://youtube.com/"><i className="fa fa-youtube"></i></a>
                        &nbsp;<a className="btn btn-social-icon" href="mailto:"><i className="fa fa-envelope-o"></i></a>
                    </div>
                </div>*/}
            </div>
            <div className="row justify-content-center">             
                <div className="col-auto">
                    <p>© Copyright 2018 Dessert Delight</p>
                </div>
            </div>
        </div>
    </div>
    )
}

export default Footer;